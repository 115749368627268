// Code injection generated by Talkcenter

export default function talkcenter() {
  if (!(Object.keys(process.env).includes('VUE_APP_HOTJAR_ID')) || process.env.VUE_APP_TALKCENTER_TOKEN === '') return;

  const tc = document.createElement("script");

  tc.type = "text/javascript";
  tc.async = true;
  tc.crossOrigin="anonymous";
  tc.integrity="sha384-6PeLFITklURfdIyyLMBNYOjcjk/RF3b/hxS4rTaQl9WGowQRVF3Q/kckTlVRMhB9";
  tc.src = "https://app.talkcenter.io/v2/talkcenter.min.js";

  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(tc, s);

  window.TalkCenter = {token: process.env.VUE_APP_TALKCENTER_TOKEN};
}
