function formatAlternatives(alternatives) {
  return alternatives.reduce((alternativesHash, alternative) => {
    alternativesHash[alternative.name] = { ...alternative };

    return alternativesHash;
  }, {});
}

function formatOptionals(optionals) {
  return optionals.reduce((optionalsHash, optional) => {
    const alternatives = formatAlternatives(optional.alternatives);
    optionalsHash[optional.name] = { ...optional, alternatives };

    return optionalsHash;
  }, {});
}

function formatQuotes(quotes) {
  return quotes.reduce((quotesHash, quote) => {
    const { metadata, ...quoteRest } = quote;
    const optionals = formatOptionals(metadata.optionals);

    quotesHash[metadata.deductible] = { ...quoteRest, ...metadata, optionals };

    return quotesHash;
  }, {});
}

export default formatQuotes;
