<template>
  <div
    class="flex flex-wrap items-center justify-between h-auto px-8 py-4 shadow-lg text-jooycar-blue"
  >
    <div class="flex items-center mb-2">
      <inline-svg :src="require('../assets/images/logos/zurich-logo.svg')" />
      <div
        v-if="insuredPersonFullName"
        class="ml-16 text-xl font-semibold capitalize"
      >
        Hola {{ insuredPersonFullName }}
      </div>
    </div>
    <div class="flex mb-2 responsive-basic-data">
      <div
        v-if="stages && stages.contractingPerson.idValue"
        class="flex"
      >
        <div class="self-center flex-shrink-0 mr-4">
          <inline-svg
            :src="require('../assets/images/icons/user.svg')"
            class="w-5 h-5"
          />
        </div>
        {{ stages.contractingPerson.idValue }}
      </div>
      <div
        v-if="carName"
        class="flex"
      >
        <div class="self-center flex-shrink-0 mx-4">
          <inline-svg
            :src="require('../assets/images/icons/car.svg')"
            class="w-5 h-5"
          />
        </div>
        <div class="flex flex-wrap">
          <div
            v-if="lead.stages.insuredVehicle.plate"
            class="mr-2"
          >
            {{ lead.stages.insuredVehicle.plate }}
          </div>
          <div>
            {{ carName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['lead']),
    ...mapState({
      stages: state => state.lead.stages,
    }),
    ...mapGetters([
      'carName',
      'insuredPersonFullName',
    ]),
    basicDataView() {
      return this.$route.path === '/datos';
    },
  },
};
</script>

<style lang="scss">
  @media (max-width: 514px) {
    .responsive-basic-data {
      flex-direction: column;
      width: 100%;

      div {
        justify-content: center;
      }
    }
  }
</style>
