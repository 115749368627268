/* eslint-disable camelcase */
import { extend, localize } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import {
  required, email, digits, required_if, length,
} from 'vee-validate/dist/rules';
import { rutValidator } from 'vue-dni';

localize('es', es);

extend('required', {
  ...required,
  message: 'Campo obligatorio',
});

extend('rut', {
  validate: rutValidator,
  message: 'Rut no válido',
});

extend('email', email);

extend('digits', {
  ...digits,
  message: (_, args) => `Debes ingresar un número de largo ${args.length}`,
});

extend('plate', {
  validate: (value) => RegExp(/^[BCDFGHJKLPRSTV-Z]{4}\d{2}$|^[A-Z]{2}\d{4}$/).test(value),
  message: 'Debes ingresar una patente válida',
});

extend('required_if', {
  ...required_if,
  message: 'Campo obligatorio',
});

extend('length', {
  ...length,
  message: (_, args) => `Debes ingresar un dato de largo ${args.length}`,
});
