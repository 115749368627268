<template>
  <transition name="slide-fade">
    <div
      v-show="visible"
      @mouseenter="clearTimer"
      @mouseleave="startTimer"
      :class="`${this.color}-notification`"
      class="fixed top-0 right-0 z-50 p-4 mx-4 mt-4 border-l-4 rounded-lg shadow-lg"
    >
      <p class="font-bold">
        {{ title }}
      </p>
      <p>{{ message }}</p>
    </div>
  </transition>
</template>

<script>
const DELETE_KEY = 46;
const BACKSPACE_KEY = 8;
const ESCAPE_KEY = 27;
const typeColorMap = {
  success: 'green',
  info: 'blue',
  warning: 'orange',
  error: 'red',
};
export default {
  data() {
    return {
      type: 'info',
      title: '',
      message: '',
      visible: false,
      closed: false,
      duration: 2500,
    };
  },
  computed: {
    color() {
      return typeColorMap[this.type];
    },
  },
  methods: {
    close() {
      this.closed = true;
      this.visible = false;
    },
    clearTimer() {
      clearTimeout(this.timer);
    },
    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },
    keydown(e) {
      if (e.keyCode === DELETE_KEY || e.keyCode === BACKSPACE_KEY) {
        this.clearTimer();
      } else if (e.keyCode === ESCAPE_KEY) {
        if (!this.closed) {
          this.close();
        }
      } else {
        this.startTimer();
      }
    },
  },
  mounted() {
    this.startTimer();
    document.addEventListener('keydown', this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown);
  },
};
</script>

<style scoped lang="scss">
  $colors: 'green', 'blue', 'orange', 'red';
  @mixin smth-color($smth, $color, $opacity) {
    @apply #{$smth}-#{$color}-#{$opacity};
  }
  @each $color in $colors {
    .#{$color}-notification {
      @include smth-color(bg, $color, 100);
      @include smth-color(border, $color, 500);
      @include smth-color(text, $color, 700);
    }
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1, .5, .8, 0);
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
