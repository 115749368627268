import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import _get from 'lodash.get';
import jooycarApi from '../api/jooycar-api';
import { BRAND_CODE, MULTIBRAND_CODE, loadingStates } from '../utils/constants';
import formatQuotes from '../utils/quoteFormatter';
import router from '../router';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ leadId: state.leadId }),
});

const defaultState = {
  dispatchAddress: {},
  inspectionInformation: {},
  owner: {},
  ownerAddress: {},
  vehicle: {},
  leadId: null,
  lead: {},
  regions: [],
  municipalities: [],
  brands: [],
  models: {},
  loadingLead: loadingStates.LOADING,
  leadError: false,
  channel: undefined,
};

function getFullMobile(person) {
  return person ? person.mobileCode + person.mobile : '';
}

const store = new Vuex.Store({
  state: defaultState,
  getters: {
    carName(state) {
      const vehicle = _get(state.lead.stages, 'insuredVehicle');

      return vehicle ? `${vehicle.displayBrand} ${vehicle.displayModel} ${vehicle.year}` : '';
    },
    insuredPersonFullName(state) {
      const person = _get(state.lead.stages, 'insuredPerson');

      return person ? `${person.firstName} ${person.lastName1} ${person.lastName2}`.trim().toLowerCase() : '';
    },
    insuredPersonFullMobile(state) {
      return getFullMobile(_get(state.lead.stages, 'insuredPerson'));
    },
    inspectionPersonFullMobile(state) {
      return getFullMobile(_get(state.lead.stages, 'inspectionPerson'));
    },
    brandsForSelect(state) {
      return state.brands.map((brands) => ({ label: brands.name, value: brands.id }));
    },
    deliveryRegion(state) {
      const deliveryAddress = _get(state.lead.stages, 'deliveryAddress');

      return deliveryAddress ? state.regions.find(region => region.id === deliveryAddress.state) || {} : {};
    },
    deliveryMunicipality(state) {
      const deliveryAddress = _get(state.lead.stages, 'deliveryAddress');

      return deliveryAddress ?
        state.municipalities.find(municipality => municipality.id === deliveryAddress.municipality) || {} :
        {};
    },
    quotes(state) {
      return _get(state.lead, 'quotes', []);
    },
    brandQuotes(_state, getters) {
      return getters.quotes.filter(({ metadata: { productCode } }) => productCode === BRAND_CODE);
    },
    multibrandQuotes(_state, getters) {
      return getters.quotes.filter(({ metadata: { productCode } }) => productCode === MULTIBRAND_CODE);
    },
    products(_state, getters) {
      return {
        [BRAND_CODE]: formatQuotes(getters.brandQuotes),
        [MULTIBRAND_CODE]: formatQuotes(getters.multibrandQuotes),
      };
    },
    selectedQuote(state) {
      return _get(state.lead.stages, 'quote');
    },
    selectedPlan: (state, getters) => (productParam = null, deductibleParam = null) => {
      const product = productParam || getters.selectedQuote.productCode;
      const deductible = deductibleParam || getters.selectedQuote.plan;

      return _get(getters.products[product], `["${deductible}"]`, {});
    },
  },
  mutations: {
    setDispatchAddress: (state, payload) => {
      state.dispatchAddress = payload;
    },
    setInspectionInformation: (state, payload) => {
      state.inspectionInformation = payload;
    },
    setLeadId: (state, payload) => {
      state.leadId = payload;
    },
    setLead: (state, payload) => {
      state.lead = payload;
    },
    setOwner: (state, payload) => {
      state.owner = payload;
    },
    setOwnerAddress: (state, payload) => {
      state.ownerAddress = payload;
    },
    setRegions: (state, payload) => {
      state.regions = payload;
    },
    setMunicipalities: (state, payload) => {
      state.municipalities = payload;
    },
    setBrands: (state, payload) => {
      state.brands = payload;
    },
    setModels: (state, payload) => {
      state.models[payload.brandId] = payload.models;
    },
    setVehicle: (state, payload) => {
      state.vehicle = payload;
    },
    updateOwner: (state, payload) => {
      state.owner = { ...state.owner, ...payload };
    },
    resetState: (state) => {
      Object.assign(state, defaultState);
    },
    setLoadingLead: (state, payload) => {
      state.loadingLead = payload;
    },
    setLeadError: (state, payload) => {
      state.leadError = payload;
    },
    setChannel: (state, payload) => {
      state.channel = payload;
    },
  },
  actions: {
    createLead({ commit }, payload) {
      jooycarApi.createLead(payload).then(({ data }) => {
        commit('setLeadId', data.id);
        commit('setLead', data);
        commit('setLoadingLead', loadingStates.IDLE);
      });
    },
    stopLoadingWithoutLead({ commit }) {
      commit('setLoadingLead', loadingStates.IDLE);
    },
    updateLeadAndRedirect({ commit, dispatch, state }, { leadToUpdate, routeName, redirectToPayment }) {
      commit('setLoadingLead', loadingStates.LOADING);
      jooycarApi.updateLead(state.leadId, leadToUpdate)
        .then((lead) => {
          commit('setLoadingLead', loadingStates.IDLE);
          commit('setLeadError', false);
          commit('setLead', lead.data);
          if (routeName) {
            router.push({ name: routeName });
          } else if (redirectToPayment) {
            dispatch('redirectToPayment');
          }
        })
        .catch(() => {
          commit('setLoadingLead', loadingStates.IDLE);
          commit('setLeadError', true);
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$notify({
            type: 'error',
            title: 'Ocurrió un problema',
            message: 'No pudimos enviar tu cotización. Por favor intenta de nuevo.',
          });
        });
    },
    getRegions({ commit }) {
      jooycarApi.getRegions().then(({ data }) => {
        commit('setRegions', data);
      });
    },
    getMunicipalities({ commit }) {
      return jooycarApi.getMunicipalities().then(({ data }) => {
        if (data.length === 0) return;

        commit('setMunicipalities', data);
      });
    },
    getBrands({ commit }) {
      jooycarApi.getBrands().then(({ data }) => {
        commit('setBrands', data);
      });
    },
    getModels({ commit }, payload) {
      return jooycarApi.getModels(payload).then(({ data }) => {
        if (data.length === 0) return;

        commit('setModels', { brandId: payload, models: data });
      });
    },
    redirectToPayment({ state }) {
      return jooycarApi.getPaymentInfo(state.leadId).then(({ data }) => {
        const form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'post';
        form.action = data.urlWebpay;
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'TBK_TOKEN';
        input.value = data.token;
        form.appendChild(input);
        form.submit();
      });
    },
    restartProcess({ commit, dispatch, state }) {
      const channel = state.channel || state.lead.channel;
      commit('resetState');
      dispatch('createLead', channel);
      router.push({ name: 'L1'});
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
