import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  { path: '/datos', component: () => import('../views/basic-data'), name: 'L1' },
  { path: '/cotizacion', component: () => import('../views/quotation'), name: 'Quote' },
  {
    path: '/informacion-adicional',
    component: () => import('../views/additional-information'),
    name: 'C1',
  },
  { path: '/resumen', component: () => import('../views/summary'), name: 'C2' },
  { path: '/locked', name: 'locked', redirect: { name: 'C2' } },
  { path: '/exito', component: () => import('../views/success'), name: 'success' },
  { path: '/error', component: () => import('../views/failure'), name: 'failure' },
];

const router = new Router({
  base: '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
