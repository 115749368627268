import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Authorization': `Bearer ${process.env.VUE_APP_API_TOKEN}` },
});
const basePath = '/api/paypermile/v1';

function createLead(channel) {
  return axiosClient.post(`${basePath}/presale`, { channel });
}

function getLead(id, code = null) {
  if (code) {
    return axiosClient.get(`${basePath}/presale?code=${code}`);
  }

  return axiosClient.get(`${basePath}/presale/${id}`);
}

function updateLead(id, params) {
  return axiosClient.put(`${basePath}/presale/${id}`, params);
}

function getRegions() {
  return axiosClient.get(`${basePath}/presale/states`);
}

function getMunicipalities() {
  return axiosClient.get(`${basePath}/presale/municipalities`);
}

function getBrands() {
  return axiosClient.get(`${basePath}/presale/brands`);
}
function getModels(brandId) {
  return axiosClient.get(`${basePath}/presale/brands/${brandId}/models`);
}

function getPaymentInfo(leadId) {
  return axiosClient.post(`${basePath}/presale/${leadId}/get_payment_url`);
}

export default {
  createLead,
  getLead,
  updateLead,
  getRegions,
  getMunicipalities,
  getBrands,
  getModels,
  getPaymentInfo,
};
