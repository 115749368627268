import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { rutInputDirective } from 'vue-dni';
import VModal from 'vue-js-modal';
import { InlineSvgPlugin } from 'vue-inline-svg';
import App from './app.vue';
import router from './router';
import store from './store';
import jooycarApi from './api/jooycar-api';
import { loadingStates } from './utils/constants';
import Notification from './utils/notification';
import './css/application.css';
import './helpers/validation-rules';
import firebase from "firebase/app";
import "firebase/analytics";
import Rollbar from 'rollbar';
import talkCenter from './third-party/talkcenter';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_ANALYTICS_API_KEY,
  authDomain: process.env.VUE_APP_ANALYTICS_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_ANALYTICS_PROJECT_ID,
  storageBucket: process.env.VUE_APP_ANALYTICS_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_ANALYTICS_MESSAGING_SENDERID,
  appId: process.env.VUE_APP_ANALYTICS_APP_ID,
  measurementId: process.env.VUE_APP_ANALYTICS_MEASUREMENT_ID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;

// Firebase alias and events
Vue.prototype.$analytics = firebase.analytics();
Vue.prototype.$analyticsEvents = {
  standard: {
    login: {
      eventName: 'login',
      params: {
        directSale: 'Funnel',
        executiveSale: 'SWF'
      },
    },
    generateLead: {
      eventName: 'generate_lead',
      params: {},
    },
    purchase: {
      eventName: 'purchase',
      params: {
        affiliation: 'CHC'
      },
    }
  },
  custom: {
    nextStage: {
      eventName: 'next_stage',
      params: {}
    },
    previousStage: {
      eventName: 'previous_stage',
      params: {},
    },
  },
  stages: {
    l1: 'basic_data - L1',
    quote: 'quotation - Quote',
    c1: 'additional_information - C1',
    c2: 'summary - C2',
  }
}

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.VUE_APP_GIT_HASH,
        guess_uncaught_frames: true
      },
    },
  }
});

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err;
};

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.directive('rut', rutInputDirective);

Vue.use(Notification);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(InlineSvgPlugin);

const LOADING_ANIMATION_DELAY = 650;
const DEFAULT_CHANNEL = 'chilena';
const VALID_CHANNELS = [
  DEFAULT_CHANNEL,
  'chil_funcionario',
  'chil_santander',
  'chil_corredor'
];

new Vue({
  render: h => h(App),
  components: {
    App,
    ValidationProvider,
    ValidationObserver,
  },
  router,
  store,
  created() {
    if (!this.$router.currentRoute.name) {
      this.$router.push({ name: 'L1', query: this.$router.currentRoute.query });
    }
  },
  mounted() {
    talkCenter();

    const channel = this.getChannel();
    const leadId = this.$store.state.leadId;
    const leadCode = this.$route.query.code;

    if (!leadId && !leadCode) {
      this.$store.dispatch('createLead', channel.name);
    } else {
      this.$store.commit('setLoadingLead', loadingStates.LOADING);
      jooycarApi.getLead(leadId, leadCode)
        .then(async (response) => {
          const leadData = response.data;

          if (!leadCode) {
            if (channel.switch) {
              await this.$store.dispatch('createLead', channel.name);
            } else {
              this.$store.commit('setLead', leadData);
              this.$store.commit('setLeadId', leadData.id);

              this.$router.push({ name: leadData.currentStage });
            }
          } else {
            this.$store.commit('setLead', leadData);
            this.$store.commit('setLeadId', leadData.id);

            this.$router.push({ name: leadData.currentStage })
          }

        })
        .finally(() => {
          this.$store.commit('setLoadingLead', loadingStates.ANIMATE);
          setTimeout(() => this.$store.commit('setLoadingLead', loadingStates.IDLE), LOADING_ANIMATION_DELAY);
        });
    }
  },
  methods: {
    getChannel() {
      return {
        switch: VALID_CHANNELS.includes(this.$route.query.channel),
        name: this.$route.query.channel || DEFAULT_CHANNEL,
      }
    }
  }
}).$mount('#app');
