<template>
  <div class="mx-12">
    <img
      :src="require('../assets/images/icons/car_loading.gif')"
      class="mt-4 duration-500 transition-spacing"
      :style="loadingCarLeftMargin"
      alt="loading-car"
    >
    <div class="flex flex-col animate-pulse">
      <div class="flex flex-row my-12">
        <div class="flex items-center flex-none mr-8">
          <div class="flex w-8 h-8 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col">
          <div class="w-56 h-10 rounded bg-jooycar-gray-stepperlight" />
        </div>
      </div>
      <div class="flex flex-col mb-2 md:flex-row">
        <div class="flex flex-col flex-1 mb-2 mr-4">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col flex-1 mb-2 mr-4">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col flex-1 mb-2">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
      </div>
      <div class="flex flex-col mb-2 md:flex-row">
        <div class="flex flex-col flex-1 mb-2 mr-4">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col flex-1 mb-2 mr-4">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col flex-1 mb-2">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
      </div>
      <div class="flex flex-col mb-2 md:flex-row">
        <div class="flex flex-col flex-1 mb-2 mr-4">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col flex-1 mb-2">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
      </div>
      <div class="flex flex-row my-12">
        <div class="flex items-center flex-none mr-8">
          <div class="w-8 h-8 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col">
          <div class="w-56 h-10 rounded bg-jooycar-gray-stepperlight" />
        </div>
      </div>
      <div class="flex flex-col mb-2 md:flex-row">
        <div class="flex flex-col flex-1 mb-2 mr-4">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
        <div class="flex flex-col flex-1 mb-2">
          <div class="w-32 h-5 mb-1 rounded bg-jooycar-gray-stepperlight" />
          <div class="w-full h-12 rounded bg-jooycar-gray-stepperlight" />
        </div>
      </div>
      <div class="flex justify-center mt-10 text-sm text-jooycar-gray-dark">
        <div class="w-32 h-5 rounded bg-jooycar-gray-stepperlight" />
      </div>
      <div class="flex justify-center my-6">
        <div class="w-48 h-12 rounded bg-jooycar-gray-stepperlight" />
      </div>
    </div>
  </div>
</template>

<script>
import { loadingStates } from '../utils/constants';

const CONTAINER_MARGIN = 96;
const CAR_ANIMATION_WIDTH = 190;

export default {
  props: {
    state: {
      type: String,
      default: loadingStates.LOADING,
    },
  },
  computed: {
    loadingCarLeftMargin() {
      const viewportWidth = document.documentElement.clientWidth;
      const margin = viewportWidth - CAR_ANIMATION_WIDTH - CONTAINER_MARGIN;

      return this.state === loadingStates.LOADING ? '' : `margin-left: ${margin}px`;
    },
  },
};
</script>
