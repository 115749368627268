<template>
  <div>
    <jooycar-header />
    <loading
      :state="loadingLead"
      class="absolute inset-0 z-10 mt-24 transition-all duration-500 ease-in-out"
      :class="!isIdle ? 'opacity-100' : 'opacity-0 z-0'"
    />
    <div
      :class="isIdle ? 'opacity-100 z-10' : 'opacity-0'"
      class="relative z-0 transition-all duration-500 ease-in-out content"
    >
      <div class="flex flex-col min-h-screen">
        <router-view class="flex-grow" />
        <jooycar-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import JooycarHeader from './components/jooycar-header';
import JooycarFooter from './components/jooycar-footer';
import { loadingStates } from './utils/constants';
import Loading from './components/loading';

export default {
  components: {
    JooycarHeader,
    JooycarFooter,
    Loading,
  },
  computed: {
    ...mapState(['loadingLead']),
    isIdle() {
      return this.loadingLead === loadingStates.IDLE;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

p {
  font-size: 2em;
  text-align: center;
}

html, body {
  font-family: 'Open Sans', sans-serif;
  color: #222b45;
}

#app {
  font-family: 'Open Sans', sans-serif;
  @apply text-jooycar-blue-darkest;
}

.backgroud-img {
  background-image: url(assets/images/background/background.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position-y: 100%;
  padding-bottom: 20%;
}

</style>
